/* You can add global styles to this file, and also import other style files */
@use 'styles.scss-theme.scss';

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.grid {
  display: grid;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-col-xs {
  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-center-center {
  justify-content: center;
  align-items: center;
}

.flex-space-around-center {
  justify-content: space-around;
  align-items: center;
}

.flex-end-start {
  justify-content: flex-end;
  align-items: flex-start;
}

.page-container-form {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 1rem;
}

.gap {
  gap: 1rem;
}

.gap-4 {
  gap: 4px;
}

.gap-8 {
  gap: 8px;
}

.gap-10 {
  gap: 10px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.margin-bottom {
  margin-bottom: 1rem;
}

.trip-details-route-details {
  .mat-expansion-panel-body {
    padding: 0 !important;
  }
}

.spinner {
  padding-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner-text {
    margin-left: 2rem;
  }
}

.search-container-with-button {
  display: grid;
  grid-template-columns: auto auto;
  align-content: center;
  grid-gap: 1rem;

  .search-box {
    align-self: center;
    .mat-mdc-form-field {
      width: 100%;
    }
  }

  .button-box {
    justify-self: end;
    align-self: center;
  }
}

.search-container {
  display: grid;
  grid-template-columns: auto;

  .search-box {
    align-self: center;
    .mat-mdc-form-field {
      width: 100%;
    }
  }
}

.search-result-text {
  flex: 100%;
  text-align: right;
  padding: 1rem 0;
}

.mat-actions-cell {
  flex: 4rem;
  max-width: 4rem;
  display: flex !important;
  justify-content: flex-start !important;
}

.mat-actions-header-cell {
  flex: 4rem;
  max-width: 4rem;
  display: flex !important;
  justify-content: flex-start !important;
}

.root-component-container {
  .page-header {
    .create-button {
      float: right;
    }
  }

  .content {
    .form-container {
      mat-form-field {
        width: 30rem;
      }

      .submit-btn {
        margin-left: 1rem;
      }
    }
  }
}

.list-card {
  float: left;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.form-field-full-width {
  width: 100%;
}

.form-group {
  max-width: 25rem;
}

.form-buttons {
  padding-top: 1rem;
}

.slide-toggle {
  padding-left: 2rem;
}

.inline-form-buttons {
  padding-left: 1rem;
}

.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}

.mat-mdc-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.mat-table-responsive {
  .mat-column-details {
    flex: 0 0 4rem;
  }
  .mat-column-status {
    flex: 0 0 5rem;
  }
  .mat-column-actions {
    flex: 0 0 3.5rem;
  }

  .mat-mdc-cell > .with-copy {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 900px) {
  .page-container-form {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 600px) {
  .mat-table-responsive {
    .mat-mdc-header-row {
      display: none;
    }
    .mat-mdc-table .mat-mdc-cell {
      display: block;
      text-align: right;
      padding: 0.5rem 1rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      min-height: auto;

      .mdc-evolution-chip-set {
        display: block;
      }
    }
    .mat-mdc-table .mat-mdc-cell:before {
      display: block;
      content: attr(data-label);
      float: left;
    }
    .mat-mdc-table .mat-mdc-cell:last-child {
      border-bottom: 1rem solid #303030;
    }
    .mat-mdc-table .mat-mdc-cell:first-child {
      border-top: 1rem solid #303030;
      text-align: center;
    }
    .mat-mdc-cell > .with-copy {
      justify-content: end;
    }
  }

  .search-container-with-button {
    display: grid;
    grid-template-columns: auto;
  }
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

/* width */
::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.26);
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.26);
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.56);
}

.max-form-width {
  max-width: 40rem;
}

.padding-top-1rem {
  padding-top: 1rem;
}

.log-setting-change-line {
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  line-height: 25px;
}

.log-setting-name {
  font-family:
    Roboto Mono,
    Consolas,
    Menlo,
    Courier,
    monospace;
  letter-spacing: normal;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.9em;
  padding: 0.2em 0.5em;
  border-radius: 4px;
  font-weight: 700;
  background: #7f7f7f;
}

.log-setting-value-to {
  font-family:
    Roboto Mono,
    Consolas,
    Menlo,
    Courier,
    monospace;
  letter-spacing: normal;
  font-size: 0.9em;
  padding: 0.2em 0.5em;
  background: #f5f7fa;
  border-radius: 4px;
  font-weight: 700;
  color: #7c609e;
  margin-right: 5px;
}

.log-setting-value-from {
  font-family:
    Roboto Mono,
    Consolas,
    Menlo,
    Courier,
    monospace;
  letter-spacing: normal;
  font-size: 0.9em;
  padding: 0.2em 0.5em;
  background: #f5f7fa;
  border-radius: 4px;
  font-weight: 700;
  color: #7c609e;
  margin-right: 5px;
  text-decoration: line-through;
}

.entity-change-chip-style {
  font-family:
    Roboto Mono,
    Consolas,
    Menlo,
    Courier,
    monospace;
  letter-spacing: normal;
  font-size: 0.9em;
  padding: 0.2em 0.5em;
  background: #2f3031;
  border-radius: 4px;
  font-weight: 700;
  color: white;
  margin-right: 5px;
}

.entity-change-property-name {
  background: #555555;
}

.entity-change-value-from {
  text-decoration: line-through;
}

.entity-change-value-to {
  text-decoration: none;
}

.license-plate {
  padding: 4px 20px;
  white-space: nowrap;
  display: inline-block;
  background-color: #fff;
  font-family: 'Segoe UI', Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
  border: 2px solid #fff;
  border-radius: 5px;
  margin-left: 0.5rem;
}

.mat-mdc-card-title {
  padding: 0.5em 16px 8px 16px;
}

.mat-mdc-card-subtitle {
  padding: 0 16px 8px 16px;
}

.mat-card-box {
  height: 100%;
}

.mdc-card__actions {
  margin-top: 8px;
  gap: 8px;
  padding: 8px 16px 16px 16px !important;
}

.custom-list-item-title {
  display: flex;
  align-items: center;
  .right-button {
    margin-left: auto;
    justify-content: center;
    display: flex;
    align-items: center;
  }
}

.mat-mdc-icon-button.input-suffix-button {
  font-size: 1em;
}

.flex-cell {
  display: flex;
}

input.mat-mdc-input-element::-webkit-calendar-picker-indicator {
  display: block;
}

/* DATE PICKER WORKAROUND */
.mat-datepicker-content,
.time-container {
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  display: block;
  border-radius: 4px;
  background-color: var(--mat-datepicker-calendar-container-background-color);
  color: var(--mat-datepicker-calendar-container-text-color);

  .mat-mdc-icon-button img,
  .mat-mdc-icon-button svg {
    stroke: var(--mat-datepicker-calendar-period-button-icon-color);
  }
}

.mat-calendar-body-cell-content {
  color: var(--mat-datepicker-calendar-date-text-color);
  border-color: var(--mat-datepicker-calendar-date-outline-color);
}

.mat-calendar-body-selected {
  background-color: var(--mat-datepicker-calendar-date-selected-state-background-color);
  color: var(--mat-datepicker-calendar-date-selected-state-text-color);
}

.mat-calendar-body-cell-content {
  border: none !important;
}

.mat-calendar-body-disabled .mat-calendar-body-cell-content {
  color: var(--mdc-icon-button-disabled-icon-color);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--mat-datepicker-calendar-date-hover-state-background-color);
}

.mat-calendar-arrow {
  fill: var(--mat-datepicker-calendar-period-button-icon-color);
}
