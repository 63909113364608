@use 'sass:map';
@use '@angular/material' as mat;
@use 'app/auth/auth.module.scss-theme.scss';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');

@include mat.all-component-typographies();
@include mat.elevation-classes();
@include mat.app-background();

// Theme definition
$primary: mat.m2-define-palette(mat.$m2-green-palette, A700);
$accent: mat.m2-define-palette(mat.$m2-green-palette, 900);
$warn: mat.m2-define-palette(mat.$m2-deep-orange-palette);
$theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

$red-color: #d53f3a;
$orange-color: #ed9c28;
$green-color: #47a447;
$grey-color: rgba(255, 255, 255, 0.7);

// TODO: might make sense to extract to a custom snackbar component
// https://material.angular.io/components/snack-bar/overview
@mixin snackbar-theme($theme) {
  $primary: map.get($theme, primary);
  $warn: map.get($theme, warn);
  .error-snackbar {
    --mdc-snackbar-container-color: #d53f3a;
    --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.7);
    --mat-snack-bar-button-color: rgba(255, 255, 255, 0.7);
  }
  .warning-snackbar {
    --mdc-snackbar-container-color: #ed9c28;
    --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.7);
    --mat-snack-bar-button-color: rgba(255, 255, 255, 0.7);
  }
  .success-snackbar {
    --mdc-snackbar-container-color: #47a447;
    --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.7);
    --mat-snack-bar-button-color: rgba(255, 255, 255, 0.7);
  }
}

@mixin button-toggle-theme($theme) {
  $primary: map.get($theme, primary);
  $warn: map.get($theme, warn);
  .mat-button-toggle-checked {
    background-color: mat.m2-get-color-from-palette($primary) !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
}

@mixin table-row-hover-theme($theme) {
  .mat-mdc-table tbody tr:hover {
    cursor: pointer;
    background: #b4b4b433;
  }
}

// Custom component theme mixins are included here
@mixin custom-components-theme($theme) {
  @include auth.auth-module-theme($theme);
  @include snackbar-theme($theme);
  @include button-toggle-theme($theme);
  @include table-row-hover-theme($theme);
}

// This is the default theme. We can add more by adding more classes.
.default-theme {
  @include mat.all-component-themes($theme);
  @include custom-components-theme($theme);
}

mat-datepicker-toggle > button {
  margin-top: -5px;
}

.image-upload-error-message {
  padding: 1rem 0;
  color: mat.m2-get-color-from-palette($warn);
}

.link-color {
  color: white;
}

.warning-text {
  color: mat.m2-get-color-from-palette($warn);
}

.link {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.material-icons.color_active {
  color: mat.m2-get-color-from-palette($accent);
}

.material-icons.color_warning {
  color: mat.m2-get-color-from-palette($warn);
}

.mat-mdc-standard-chip {
  background-color: #616161 !important;
}

.mat-mdc-standard-chip.active {
  background-color: mat.m2-get-color-from-palette($accent) !important;
  font-weight: 400;
}

.mat-mdc-standard-chip.active {
  background-color: mat.m2-get-color-from-palette($accent) !important;
  font-weight: 400;
}

.mat-mdc-standard-chip.terminated {
  background-color: $red-color !important;
  font-weight: 400;
}

.mat-mdc-standard-chip.accepted {
  background-color: #5bc0de !important;
  font-weight: 400;
}

.mat-mdc-standard-chip.inprogress {
  background-color: #08c !important;
  font-weight: 400;
}

.mat-mdc-standard-chip.completed {
  background-color: $green-color !important;
  font-weight: 400;
}

.mat-mdc-standard-chip.expired {
  background-color: $orange-color !important;
  font-weight: 400;
}

.mat-mdc-standard-chip.cancelled {
  background-color: $red-color !important;
  font-weight: 400;
}

.mat-mdc-standard-chip.claimed {
  background-color: $green-color !important;
  font-weight: 400;
}

.mat-mdc-standard-chip.enabled {
  background-color: rgba(30, 230, 100, 0.2) !important;

  .mat-mdc-chip-action-label {
    color: #1ee664 !important;
  }
}

.mat-mdc-standard-chip.disabled {
  background-color: rgba(183, 183, 183, 0.2) !important;

  .mat-mdc-chip-action-label {
    color: #b7b7b7 !important;
  }
}

.active-link {
  background: rgba(255, 255, 255, 0.04);
}

.search-input {
  padding-bottom: 5px;
}

.badge {
  box-sizing: border-box;
  display: inline-block;
  border-radius: 2rem;
  text-align: center;
  padding: 0.125rem 0.5rem;
  font-size: 14px;
  font-weight: normal;
  line-height: inherit;
  margin: 0 0 0.125rem 0.5rem;
}

.badge-red {
  background-color: $red-color;
  color: white;
}

.badge-orange {
  background-color: $orange-color;
  color: white;
}

.badge-blue {
  background-color: #08c;
  color: white;
}

.badge-warning {
  background-color: $orange-color;
  color: white;
}

.green-icon {
  color: $green-color;
}

.orange-icon {
  color: $orange-color;
}

.red-icon {
  color: $red-color;
}

.delete-icon {
  color: #939393 !important;
}

.grey-text {
  color: rgba(255, 255, 255, 0.7);
}

.not-set-text {
  color: #989898;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mdc-evolution-chip-set .mdc-evolution-chip {
  margin-left: 8px;
  margin-right: 0;
}

.row-link:hover {
  cursor: pointer;
  background: #b4b4b433;
}

.density-minimum {
  @include mat.all-component-densities(minimum);
}
