@use 'sass:map';
@use '@angular/material' as mat;
@mixin user-theme($theme) {
  $warn: map.get($theme, warn);

  .cdk-overlay-container {
    .log-out-menu-item {
      span {
        color: mat.m2-get-color-from-palette($warn) !important;
      }
    }
  }
}
