@use 'sass:map';
@use '@angular/material' as mat;
@mixin login-theme($theme) {
  $primary: map.get($theme, primary);

  .login-component-container {
    .reset-password {
      color: mat.m2-get-color-from-palette($primary);
    }
  }
}
